<template>
  <div class="div_title">
    <context :context=cate />
    <policies/>
  </div>
  <faq />
  <div class="div_title"><span style="color: #ff7f50;">영상</span>
    <select ref="inputYoutube" @change="click()" v-model="youtube" style="padding:5px; float:right; margin-top:-5px; margin-right:10px;text-align:center;">  <!-- 카카오 ID로 검색 -->
      <option value = "">전체보기</option>
    </select>
  </div>
  <carousel :settings="settings" :breakpoints="breakpoints" :key="carousellKey">
    <slide v-for="(youtube, index) in youtubeList.value" :key="index">
      <div class="carousel__item">
        <iframe width="75%" height="75%" :src=youtube.content frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
  <div class="ad-container">
    <Adsense data-ad-client="ca-pub-6699354447815429" data-ad-slot="8230887469" data-ad-format="horizontal" data-full-width-responsive="true" />
  </div>
  <Modal v-model='isShowFaq' :close='closeModal' style="z-index: 99999999999;">
    <div id="modal_faq" class="modal">
      <div class="div_title" style="text-align: left;margin-top:-20px;">
        <iframe width="100%" height="300px" :src=this.youtube frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <br>
      <p v-html="notice_content"></p>
      <span><button class="modal_button" @click="closeModal()" style="margin-top: 20px;">close</button></span>
    </div>
  </Modal>
</template>

<script>
// import { useMeta } from 'vue-meta'
import faq from './components/home/faq.vue'
import { ref, inject} from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import ssr from './components/common/ssr'

export default {
  emits: ["setMenu", "login", "logout"],
  mounted() {
    this.addJsonLd()
    this.api.setPage('faq')
    this.$emit("setMenu", {page:'faq'})
    console.log(this.api.getCookies('page'))    
    this.emitter.on('faqReload', this.reload)
    this.getYoutube()
  },  
  unmounted() {
    this.emitter.off('faqReload', this.reload)
  },
  components: {
    faq,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    context,
    policies
  },
  data() {
    return {
      // idx : '-1',
      // mIdx : '0',
      carousellKey : 0,
      youtubeList : ref([]),
      youtube : ''
    }
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > FAQ'
    const keywords = '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트,정책,수수료,비용,가격,견적서'
    const description = '개인/기업 업무와 관련된 GEEK9 정책 및 규정'
    const geek9Url = ssr.GEEK9_URL + 'faq'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    // useMeta( {
    //   title: 'GEEK9 > FAQ',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/faq'}],
    //   meta: [
    //     { vmid: 'charset_faq', charset: 'utf-8' },
    //     { vmid: 'http-equiv_faq', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_faq', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_faq', name: 'keywords', content:'직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트,정책,수수료,비용,가격,견적서,' },
    //     { vmid: 'viewport_faq', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_faq', name: 'description', content: '개인/기업 업무와 관련된 GEEK9 정책 및 규정' },
    //     { vmid: 'theme-color_faq', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_faq', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_faq', property: 'og:description', content: '개인/기업 업무와 관련된 GEEK9 정책 및 규정'},
    //     { vmid: 'og:title_faq', property: 'og:title', content: 'GEEK9 > FAQ'},
    //     { vmid: 'og:image_faq', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_faq', property: 'og:url', content: 'https://geek9.kr/faq'}
    //   ]
    // })
    const addJsonLd = () => {
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          el.remove()
        }
      })
      const script = document.createElement('script')
      script.setAttribute('type', 'application/ld+json')
      script.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
          ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
          {
            "@type": "FAQPage",
            "name": "자주 묻는 질문",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "해외직구 대행 수수료가 얼마인가요?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>구매대행 : 구매가의 10%가 수수료이고, 최소 수수료는 8,000원 입니다.</p><p>배송대행은 수수료 없이 한국으로 보내는 배송비만 지불하시면 됩니다.</p>"
                }
              }, {
                "@type": "Question",
                "name": "배송기간이 얼마나 걸리나요?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "해외 판매자가 물건을 보내주고 해외 GEEK9 물류 창고에서 물건을 받은 시점에서 한국에서 물건을 받기까지는 최소 1~2주의 시간이 필요합니다."
                }
              }, {
                "@type": "Question",
                "name": "해외 사기거래가 발생한 경우 보상 받을 수 있나요?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "GEEK9는 소비자님께서 요청해주신 해외 판매자/사이트에서 대신 구매를 하고, 한국으로 배송을 보내는 업무만을 담당하기 때문에 해외 판매자/사이트가 사기꾼인거나 상품의 가품 여부 등은 GEEK9에서도 알 수 있는 방법이 없기 때문에 어떠한 보상이나 책임을 지지 않습니다."
                }
              }, {
                "@type": "Question",
                "name": "대행수수료가 왜 이렇게 비싸죠?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "실제 해외 판매자/사이트에서 결제를 하게 되면 해외 판매사이트의 수수료, 세금, 착불, 해외현지배송비 등이 추가되고, 그 추가된 기준으로 GEEK9의 수수료가 책정이 되기 때문에 이러한 비용에 대해서 확인할 수 없는 소비자님 입장에서는 예상했던 견적보다 비용이 더 많다고 생각할 수밖에 없습니다."
                }
              }, {
                "@type": "Question",
                "name": "수출입/해외보관/풀필먼트 서비스도 가능하나요?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "GEEK9 개인 해외직구 뿐만이 아니라 기업의 해외 물류 업무도 처리하고 있습니다. 수출입/통관/3PL 등의 기업 물류 업무는 https://geek9.kr/biz 에서 자세한 내용을 확인하실 수 있습니다."
                }
              }
            ]
          }
        ]
      })
      document.head.appendChild(script)
    }
    const isShowFaq = ref(false)
    const showModal = async () => {
      isShowFaq.value = true;
    }
    const closeModal = async () => {
      isShowFaq.value = false
    }
    const cate = ref([
      { label: '게시판', route:'https://contents.geek9.kr' },
      { label: '필독', route: '/faq' }
    ])
    return {
      settings: {
				itemsToShow: 1,
				snapAlign: "center"
			},
			breakpoints: {
				700: {
					itemsToShow: 2,
					snapAlign: "center"
				},
				1024: {
					itemsToShow: 2,
					snapAlign: "start"
				}
			},
      isShowFaq, showModal, closeModal, cate, addJsonLd
		}
  },
  methods: {
    reload(flag) {
      if(flag === 'dashboard') {
        this.$emit("setMenu", {page:'faq'})
      }
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.emitter.emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //       } 
    //     },
    //     fail: (error) => {
    //       this.$notify("다시 시도해주세요.")
    //       console.log(error)
    //     }
    //   })
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.tmp = '로그인'
    //       this.request();
    //     },
    //     fail: () => {
    //       this.$notify("다시 시도해주세요.")
    //     },
    //   })
    // },
    // logout() {
    //   window.Kakao.Auth.logout(() => {
    //     this.emitter.emit("setCookies", {login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
    //     this.tmp = 'not'
    //     this.$notify('로그아웃 되었습니다.');
    //   });
    // },    
    click() {
      const webYoutubeUrl = String(this.youtube).replace('embed/', 'watch?v=')
      if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
        this.showModal()
      } else window.open(webYoutubeUrl, '_blank')
    }, 
    async getYoutube() {
      await this.api.axiosGql('SELECT_NOTICE', {type:'SELECT_NOTICE', noticeType: 'youtube', useYn:true })
      .then( async (res) => {
        let json = res.data
        let list = Array()
        json.map((notice) => {
          if(notice.USE_YN === true) {
            list.push( {content:notice.CONTENT, title:notice.TITLE} )
            let option = document.createElement('option')
            option.value = notice.CONTENT
            option.text = notice.TITLE
            this.$refs.inputYoutube.add(option)
          } 
        })
        this.youtubeList.value = list
        this.carousellKey += 1
        let select = this.$refs.inputYoutube
        select.options[0].style.display = 'none' 
      })
    },
  }
}
</script>

<style scoped>
.carousel__item {
	width: 100%;
  height:400px;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
  